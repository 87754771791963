












































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import moment from 'moment';
import { reservationModule } from '@/store';
import { EventBus } from '@/config/config';
import { find } from 'lodash';

@Component({
  components: {
    'alert-modal': () => import('@/components/popup/AlertModal.vue')
  }
})
export default class ToReserveModal extends mixins(DataFormat) {
  @Prop()
  public type!: any;
  @Prop()
  public selectLang!: any;

  public $refs!: {
    calendar: any,
    scrollWarp: HTMLElement
  }
  public swiper: any = null;
  public requestCount: number = 0;
  public isRequest: boolean = false;
  public requestText: string = '';
  public isModalClose: boolean = false;
  public reservationId: any = null;
  public optionChecked: any = [];
  public getOptionChecked: any = [];
  public isLoading: boolean = false;
  public timeCheck: number = 0;
  public reserveTime: any = null;
  public addCount: number = 0;
  public firstParams: any = {
    date: new Date(),
    time: null,
    person: 2,
    message: ''
  }
  public params: any = {
    id: null,
    date: new Date(),
    person: 2,
    except_person: 0
  }
  public person: number = this.params.person;
  public selectedDate: any = null;
  public reserveClose: boolean = true;
  public errorMessage: string = '';
  public optionList: any = [];
  public openState: boolean = false;
  public testDate: any = '2021/02/01';
  public height: number = 120;
  public personMin: number = 0;
  public personMax: number = 0;
  public personKind: any = [];
  public personTotal: number = 2;
  public inquiryPerson: number = this.params.person;
  public firstPersonKind: any = [];
  public optionPriceShow: boolean = true;

  // api 호출
  @Watch('selectedDate')
  public fetchData() {
    if (this.reserveTime && this.openState) this.reserveTime = null;
    this.optionChecked = [];
    if (this.selectedDate === null) {
      this.params.date = null;
    } else {
      this.params.date = moment(this.selectedDate).format('YYYY-MM-DD');
      this.fetchOptionData();
    }
    if (!this.openState) this.openState = true;
    setTimeout(() => {
      this.activeSwiperTime();
    }, 300);
  }

  @Watch('schedule')
  public disabledDate() {
    const isFind = find(this.schedule.off_days, (row) => row === this.params.date);
    if (isFind) {
      this.params.date = null;
      this.selectedDate = null;
      this.$q.notify('해당 날짜는 예약이 불가능합니다.');
    }
    this.optionActive();
    // this.reLoad();
  }

  public onClickDay(date: any) {
    if (date.el._prevClass.includes('is-disabled')) {
      return;
    }
    if (this.params.date === date.id) {
      if (this.reserveTime !== null) {
        this.reserveTime = null;
      }
    }
    setTimeout(() => {
      this.$emit('reservation-date', this.params);
    }, 100);
  }

  // 예약하기 닫기
  public toReserveClose() {
    this.isModalClose = true;
    setTimeout(() => {
      this.$modal.hide('toReserve');
    }, 650);
    EventBus.$emit('updateReserve', this.params);
    EventBus.$emit('updateReserveTime', this.reserveTime);
    EventBus.$emit('updateTimeCheck', this.timeCheck);
    EventBus.$emit('updateReserveModal', this.reserveClose);
    EventBus.$emit('optionChecked', this.optionChecked);

    const cartDate: any = this.params.date + ' ' + this.reserveTime;
    const payload: any = {
      product_id: this.params.id,
      buy_option: [],
      date: moment(cartDate).format('YYYY-MM-DD HH:mm:ss'),
      person: this.params.person
    };
    if (this.reservationTimeData.party_size_detail && this.reservationTimeData.party_size_detail.length) {
      payload.person = this.person;
      for (let i = 0; i < this.personKind.length; i++) {
        payload[`person_${this.reservationTimeData.party_size_detail[i].key}`] = this.personKind[i].person;
      }
    }
    this.$emit('cart-info-data', payload);
  }

  // 초기화
  public reset() {
    this.person = this.firstPersonKind && this.firstPersonKind[0] ? this.firstPersonKind[0] : this.firstParams.person;
    this.inquiryPerson = this.firstParams.person;
    this.selectedDate = new Date(this.firstParams.date);
    this.$nextTick(() => {
      this.reserveTime = this.firstParams.time;
    });
    this.requestText = '';
    this.isRequest = false;
    if (this.reservationTimeData && this.reservationTimeData.party_size_detail.length) {
      this.personKind = [];
      for (let i = 0; i < this.reservationTimeData.party_size_detail.length; i++) {
        this.personKind.push({
          id: this.reservationTimeData.party_size_detail[i].key,
          person: this.firstPersonKind[i] ? this.firstPersonKind[i] : i === 0 ? this.firstParams.person : 0,
          name: this.reservationTimeData.party_size_detail[i].value
        });
        if (this.personKind[i] && this.personKind[i].name && this.personKind[i].name.includes('유아')) {
          this.params.except_person = this.personKind[i].person;
        }
      }
    }
    this.$emit('reservation-date', this.firstParams);
  }
  public resetData() {
    this.firstParams.person = this.params.person;
    this.person = this.params.person;
    this.inquiryPerson = this.params.person;
    // this.personKind[0].person = this.params.person;
    this.firstParams.date = this.params.date;
    this.firstParams.time = this.reserveTime;
    this.optionChecked = this.getOptionChecked;
  }

  public mounted() {
    if (this.$route.name === 'product') {
      this.params.id = this.$route.params.productId;
    }
    reservationModule
      .fetchProductCalendar({product_id: this.params.id});
    this.activeSwiperTime();
    EventBus.$on('updateReserve', (data) => {
      this.params = data;
    })
    EventBus.$on('updateReserveDate', (data) => {
      if (data === null) {
        this.selectedDate = null;
        this.fetchOptionData();
      } else {
        this.selectedDate = new Date(data);
      }
    })
    EventBus.$on('updateReserveTime', (data) => {
      this.reserveTime = data;
    })
    EventBus.$on('updateTimeCheck', (data) => {
      this.timeCheck = data;
    })
    EventBus.$on('optionChecked', (data) => {
      this.getOptionChecked = data;
    })
    EventBus.$on('timeBlockIndex', (data) => {
      this.timeCheck = data;
      setTimeout(() => {
        this.activeSwiperTime(this.timeCheck);
      }, 500);
    })
    EventBus.$on('updatePersonDetail', (data: any) => {
      if (data[`person_${this.reservationTimeData.party_size_detail[0].key}`]) {
        this.params.person = data.person;
        this.person = data.person;
        this.inquiryPerson = data.person;
        for (let i = 0; i < this.personKind.length; i++) {
          const num = `person_${this.personKind[i].id}`;
          const re = data[num];
          this.personKind[i].person = re;
        }
      }
    });
    this.personKindUpdate();
  }

  public fetchOptionData() {
    this.params.id = this.$route.params.productId;
    let params = {
      product_id: this.params.id,
      reservation_date: this.params.date,
      reservation_time: this.reserveTime,
      person: this.person
    }
    if (this.reservationTimeData && this.reservationTimeData.party_size_detail && this.reservationTimeData.party_size_detail.length) {
      // params.person = this.personTotal; zz
      params.person = this.person;
    }
    if (params.person && this.reserveTime) {
      reservationModule
        .fetchProductOptions(params)
        .then((res) => {
          if (res.data) {
            this.optionActive();
          }
        });
    }
  }
  // public scheduleData() {
  //   if (this.$route.name === 'product') {
  //     this.params.id = this.$route.params.productId;
  //     reservationModule.fetchProductSchedule(this.params).then((res: any) => {
  //       if (res.data.min_size > this.person) this.person = res.data.min_size;
  //       else if (res.data.max_size < this.person) this.person = res.data.max_size;
  //     });
  //   } else if (this.$route.name === 'place') {
  //     this.params.id = this.$route.params.placeId;
  //     reservationModule.fetchPlaceSchedule(this.params).then((res: any) => {
  //       if (res.data.min_size > this.person) this.person = res.data.min_size;
  //       else if (res.data.max_size < this.person) this.person = res.data.max_size;
  //     });
  //   } else {
  //     if (this.type.product === null) {
  //       this.params.reservation_id = this.$route.params.id;
  //       reservationModule.fetchPlaceSchedule(this.params);
  //     } else {
  //       this.params.reservation_id = this.$route.params.id;
  //       reservationModule.fetchProductSchedule(this.params);
  //     }
  //   }
  // }

  public inputHeight() {
    const textarea = document.querySelector('.text-area' as any);
    textarea.addEventListener('input', () => {
      this.height = textarea.scrollHeight;
    });
  }

  // swiper set
  public activeSwiperTime(first?: any) {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    const swiper = new Swiper('.swiper-container', {
      initialSlide: first ? first : 0,
      slidesPerView: 'auto',
      freeMode: true,
      observer: true,
      observeParents: true,
    });
  }

  // 인원 증가/감소
  public personChange(amount) {
    this.addCount = amount;
    //예약 변경 + 구매 수량 제한이 있는 경우 (min, total) => 인원 증감 불가
    if (this.$route.name === 'detail-reservation') {
      if (this.schedule.check_count_type !== 'no') {
        this.$q.notify('해당 예약은 인원 변경이 불가합니다.');
        return false;
      }
    }

    const calc = this.person + amount;
    const min_size = this.reservationTimeData && this.reservationTimeData.capacity[0].size;
    const max_size = this.reservationTimeData && this.reservationTimeData.capacity[this.reservationTimeData.capacity.length - 1].size;

    if (calc < min_size || calc > max_size) {
      this.$q.notify(`${min_size} ~ ${max_size}명으로 예약이 가능합니다.`);
      return false;
    }

    if (this.$route.name === 'product' && !this.isEmpty) {
      this.$modal.show('confirm');
      // if (confirm('일정 및 옵션을 \n재선택하시겠습니까?')) {
      //   if (calc >= min_size && max_size >= calc) {
      //     this.selectedDate = null;
      //     this.reserveTime = null;
      //     this.optionChecked = [];
      //     return this.person = calc
      //   }
      // }
    } else {
      if (calc >= min_size && max_size >= calc) {
        this.selectedDate = null;
        this.reserveTime = null;
        return this.person = calc
      }
    }
  }
  public personDisable(key: any, person: number, index: number) {
    const left_at: number = this.personMax - this.person;
    const each_max: number = this.personKind[index] && this.personKind[index].person + left_at;
    // if (this.personKind[index] && this.personKind[index].name && this.personKind[index].name.includes('유아')) {
    //   return false;
    // }
    if (person <= each_max) {
      return false;
    }
    return true;
  }
  public personClick(event: any, person: number, index: number, type?: string) {
    if (type) {
      if (event.srcElement.classList.value.includes('disabled')) {
        return;
      } else if (event.srcElement.classList.value.includes('active')) {
        this.personKind[index].person = 0;
        if (this.personKind[index] && this.personKind[index].name && this.personKind[index].name.includes('유아')) {
          this.params.except_person = 0;
        }
      } else {
        this.personKind[index].person = person;
        if (this.personKind[index] && this.personKind[index].name && this.personKind[index].name.includes('유아')) {
          this.params.except_person = person;
        }
      }
    } else {
      if (event.srcElement.classList.value.includes('active')) {
        return;
      } else {
        this.person = person;
      }
    }
    this.$emit('reservation-date', this.params);
  }
  @Watch('person')
  public fetchPerson() {
    this.params.person = this.person;
    if (this.timeBlockData) {
      if (!this.timeBlockData.is_active) {
        this.reserveTime = null;
      } else {
        const re = this.timeBlockData.times.filter((row: any) => {
          return row.time === this.reserveTime && row.is_active;
        });
        if (re.length) {
          this.fetchOptionData();
          this.optionChecked = [];
        } else {
          this.reserveTime = null;
        }
      }
    }
    this.$emit('person', this.person);
  }

  public addPerson(count) {
    const calc = this.person + count;
    const min_size = this.schedule.min_size;
    const max_size = this.schedule.max_size;

    if (calc >= min_size && max_size >= calc) {
      this.selectedDate = null;
      this.reserveTime = null;
      this.optionChecked = [];
      this.$modal.hide('confirm');
      return this.person = calc
    } else {
      this.$q.notify(`${min_size} ~ ${max_size}명으로 예약이 가능합니다.`);
      this.$modal.hide('confirm');
    }
  }

  // 달력
  get calendarLimit() {
    const range = this.schedule.show_calendar_range ? this.schedule.show_calendar_range - 1 : 1;

    return {
      minDate: (this.schedule && moment(this.schedule.reserve_start_date).toDate() > new Date()) ? moment(this.schedule.reserve_start_date).toDate() : new Date(),
      maxDate: (this.schedule && moment(this.schedule.reserve_start_date).toDate() > new Date()) ?
              moment(this.schedule.reserve_start_date).add(range, 'M').endOf('month').toDate() :
              moment().add(range, 'M').endOf('month').toDate()
    }
  }
  // get calendarLimit() {
  //   return {
  //     minDate: this.calendarData && moment(this.calendarData.calendar_start_date).toDate(),
  //     maxDate: this.calendarData && moment(this.calendarData.calendar_end_date).toDate()
  //   }
  // }
  get endDate() {
    if (this.schedule.end_date) {
      const settingDate: any = moment(this.schedule.end_date);
      if (moment(settingDate).get('hour') === 0) {
        return new Date(settingDate);
      }
      return moment(settingDate)
        .add(1, 'day')
        .startOf('day')
        .toDate();
    }
    return new Date().setMonth(new Date().getMonth() + 2, 1);
  }
  get offDays() {
    const minDate: any = this.calendarData.calendar_start_date;
    const maxDate = this.calendarData.calendar_end_date;
    const days: any = [];
    for (let date = minDate; date <= maxDate;) {
      days.push(date);
      date = moment(date).add(1, 'day').format('YYYY-MM-DD')
    }
    const offDays = days;
    for (let i = 0; i <= days.length; i++) {
      this.calendarData.list_able_date.filter((list: any) => {
        if (days[i] === list) {
          offDays.splice(i, 1);
        }
      })
    }
    return offDays;
  }
  public reLoad() {
    const ca = this.$refs.calendar.$refs.calendar;

    if (moment(this.schedule.reserve_start_date).toDate() > new Date()) {
      ca.move({
        // month: Number(moment(this.schedule.reserve_start_date).format('M')),
        month: 2,
        year: Number(moment(this.schedule.reserve_start_date).format('YYYY'))
      });
    }
  }

  // 타임블럭 disable
  public timeDisable(index) {
    if (!this.timeBlockData.times[index].is_active || this.selectedDate === null) {
      return true;
    } else {
      return false;
    }
  }
  public toData(index) {
    this.timeCheck = index;
    EventBus.$emit('updateTimeCheck', this.timeCheck);
  }

  // 요청사항 최대글자(한글/영문)
  get maxLength() {
    const last = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    const re = last.test(this.requestText.substr(999, 1));
    if (re) {
      return 999;
    }
    return 1000;
  }

  // 옵션 선택
  public selectOption(event: any, index: number, type?: string) {
    const { checked } = event.target;
    if (type === 'radio') {
      const re = this.optionChecked.filter((row: any) => {
        return row > 0
      });
      if (re.length) {
        for (let i = 0; i <= this.productOptions.data.length - 1; i++) {
          this.$set(this.optionChecked, i, i === index ? this.productOptions.data[index].min_count : 0);
        }
        return;
      }
      this.$set(this.optionChecked, index, checked ? this.productOptions.data[index].min_count : 0);
    } else {
      this.$set(this.optionChecked, index, checked ? this.productOptions.data[index].min_count : 0);
    }
  }
  public optionDisabled(index) {
    if (
      this.productOptions.data[index].is_active === 1 ||
      this.selectedDate === null ||
      this.reserveTime === null ||
      this.optionList[index] && this.optionList[index].is_active === false
    ) {
      return true;
    } else {
      return false;
    }
  }
  // 선택한 타임블럭의 옵션 -> 처음 모달 열었을때 reserveTime이 null 값으로 넘어옴
  @Watch('reserveTime')
  public fetchOption(newValue: any, oldValue: any) {
    this.fetchOptionData();
    this.optionChecked = [];
    if (
      newValue !== '' &&
      (oldValue === '' || oldValue === null)
    ) {
      const el1: any = document.querySelector('.calendar-area');
      const el2: any = document.querySelector('.person-area');
      if (el1 && el2) {
        const moveHeight = el1.scrollHeight + el2.scrollHeight;
        setTimeout(() => {
          this.$refs.scrollWarp.scrollTo(0, moveHeight);
        }, 200);
      }
    }
  }

  public optionActive() {
    if (this.reserveTime !== null && this.$route.name === 'product' && this.productOptions) {
      this.optionList = this.productOptions.data;
    }
  }

  // 옵션 수량 증가/감소
  public changeQuantity(e, index, amount) {
    const { target } = e;
    const { min_count: min, max_count: limit } = this.optionList[index];
    const calc = this.optionChecked[index] + amount;

    if (calc >= min && calc <= limit) {
      this.optionPriceShow = false;
      this.$set(this.optionChecked, index, calc);
      this.$nextTick(() => {
        this.optionPriceShow = true;
      });
    } else {
      this.errorMessage = `해당 상품은 최소 ${min}매, 최대 ${limit}매 구매 가능합니다.`;
      EventBus.$emit('errorMessage', this.errorMessage);
      this.$modal.show('alert');
    }
  }
  // 선택된 옵션 삭제
  public deleteOption(index) {
    this.$set(this.optionChecked, index, 0);
  }

  // 요청사항 toggle
  public requestToggle() {
    this.isRequest = !this.isRequest;
  }

  // 총결제금액
  get totalPrice() {
    if (this.schedule.option) {
      return this.schedule.option.reduce((sum: any, option: any, idx: any) => {
        return sum + option.discount_price * (this.optionChecked[idx] || 0);
      }, 0);
    }
  }
  get isEmpty() {
    if (this.$route.name === 'product') {
      return this.optionChecked.every((e) => !e);
    } else {
      if (this.person !== null && this.selectedDate !== null && this.reserveTime !== null) {
        return false;
      } else {
        return true;
      }
    }
  }

  get paymentText() {
    return '다음';
    // let price = '';
    // if (this.totalPrice) {
    //   price = this.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // }
    
    // if (this.$route.name === 'product' && !this.isEmpty) {
    //   return `${price}원 결제하기`;
    // } else {
    //   return '결제하기';
    // }
  }

  // 최종 버튼 (예약하기, 결제하기, 예약변경, 예약변경요청)
  public onSubmit() {
    this.isLoading = true;
    const cartDate: any = this.params.date + ' ' + this.reserveTime;
    // 티켓 - 결제하기
    if (this.$route.name === 'product') {
      let isAvailable: boolean = false;
      let optionCount: any = 0;
      const payload: any = {
        product_id: this.params.id,
        buy_option: [],
        date: moment(cartDate).format('YYYY-MM-DD HH:mm:ss'),
        person: this.params.person,
        // message: this.requestText
      }

      this.optionChecked.forEach((option, index) => {
        if (option > 0) {
          payload.buy_option.push({
            id: this.productOptions.data[index].id,
            count: option
          });
        }
        optionCount += (option * this.productOptions.data[index].standard_stock);
      });

      if (this.productOptions.check_count_type === 'no') isAvailable = true; //구매 수량 제한이 없는 경우
      else if (this.productOptions.check_count_type === 'min' && optionCount >= payload.person) isAvailable = true; //구매 수량 제한이 'min'이고, 최소 수량 이상 선택한 경우
      else if (this.productOptions.check_count_type === 'total' && optionCount == payload.person) isAvailable = true; //구매 수량 제한이 'total'이고, 제한 수량과 일치하게 선택한 경우

      if (this.reservationTimeData.party_size_detail && this.reservationTimeData.party_size_detail.length) {
        payload.person = this.person;
        for (let i = 0; i < this.personKind.length; i++) {
          payload[`person_${this.reservationTimeData.party_size_detail[i].key}`] = this.personKind[i].person;
        }
      }
      payload.buy_option = JSON.stringify(payload.buy_option);
      this.isLoading = false;
      this.$emit('cart-info-data', payload);

      if (!isAvailable) {
        this.errorMessage = '방문인원과 티켓수량이 맞지 않습니다. 인원수 또는 티켓수량을 확인해주세요.';
        EventBus.$emit('errorMessage', this.errorMessage);
        this.$modal.show('alert');
        this.isLoading = false;
        return false;
      }

      this.$modal.show('reservationModal');

      // reservationModule
      //   .fetchCart({
      //     product_id: payload.product_id,
      //     buy_option: JSON.stringify(payload.buy_option),
      //     date: payload.date,
      //     person: payload.person,
      //     message: payload.message
      //   })
      //   .then((res) => {
      //     if (!res.status) {
      //       this.errorMessage = res.error.message;
      //       EventBus.$emit('errorMessage', this.errorMessage);
      //       this.$modal.show('alert');
      //     } else {
      //       this.$router.push({ name: 'payment', params: {cartId: res.data.id} });
      //     }
      //   })
      //   .finally(() => {
      //     this.isLoading = false
      //   })
    } else if (this.$route.name === 'place') { // 매장 - 예약하기
      this.$modal.show('reservationModal');
      const payload: any = {
        place_id: this.params.id,
        date: moment(cartDate).format('YYYY-MM-DD HH:mm:ss'),
        person: this.params.person,
        message: this.requestText
      }

      // reservationModule
      //   .fetchReservationRegister(payload)
      //   .then((res) => {
      //     if (!res.status || res.status === '404') {
      //       this.errorMessage = res.error.message;
      //       EventBus.$emit('errorMessage', this.errorMessage);
      //       this.$modal.show('alert');
      //     } else {
      //       this.reservationId = res.data.id;
      //       this.$modal.show('reservationComplete');
      //       this.$modal.hide('toReserve');
      //     }
      //   })
      //   .finally(() => {
      //     this.isLoading = false
      //   })
    } else { // 예약 변경 요청, 예약 변경
      this.$modal.show('reservationModal');
      const params: any = {
        reservationId: this.type.id,
        date: moment(cartDate).format('YYYY-MM-DD HH:mm:ss'),
        person: this.params.person,
        message: this.requestText
      }

      reservationModule
        .fetchReservationChange(params)
        .then((res) => {
          if (!res.status) {
            this.errorMessage = res.error.message;
            EventBus.$emit('errorMessage', this.errorMessage);
            this.$modal.show('alert');
          } else {
            this.$q.notify('예약 변경이 완료되었습니다.');
            this.$modal.hide('toReserve');
            // this.$router.push({ path: `/user/${this.userId}` });
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }

  // 예약 변경 검증
  get reserveChangeValidate() {
    if (
      moment(this.firstParams.date).format('YYYY-MM-DD') === moment(this.selectedDate).format('YYYY-MM-DD') &&
      this.firstParams.person === this.params.person &&
      this.firstParams.time === this.reserveTime &&
      this.firstParams.message === this.requestText
    ) {
      return true
    } else {
        if (this.selectedDate === null || this.reserveTime === null) {
          return true;
        } else return false;
    }
  }

  // 모달 상태 이벤트
  public modalOpened() {
    document.querySelector('html' as any).classList.add('open-modal');
    this.isModalClose = false;
    this.openState = true;
    this.personMin = this.reservationTimeData && this.reservationTimeData.capacity[0].size;
    this.personMax = this.reservationTimeData && this.reservationTimeData.capacity[this.reservationTimeData.capacity.length - 1].size;
    EventBus.$on('timeBlockIndex', (data) => {
      this.timeCheck = data;
    })
    if (this.reservationTimeData && this.reservationTimeData.party_size_detail.length && !this.personKind.length) {
      this.personKind = [];
      for (let i = 0; i < this.reservationTimeData.party_size_detail.length; i++) {
        this.personKind.push({
          id: this.reservationTimeData.party_size_detail[i].key,
          person: i === 0 ? 2 : 0,
          name: this.reservationTimeData.party_size_detail[i].value
        });
        if (this.personKind[i] && this.personKind[i].name && this.personKind[i].name.includes('유아')) {
          this.params.except_person = this.personKind[i].person;
        }
      }
    }
    // 매장 일반 예약일 경우
    // if (this.$route.name === 'place' && this.type.reservation_type === 'normal') {
    //   this.selectedDate = new Date()
    //   this.params.id = this.type.id;
    //   this.params.date = this.selectedDate;
    //   this.params.person = 2;
    //   this.person = 2;
    //   if (this.firstParams.date && this.firstParams.person) {
    //     this.selectedDate = new Date(this.firstParams.date);
    //     this.person = this.firstParams.person;
    //     this.reserveTime = this.firstParams.time;
    //   }
    // }
    // 예약 변경일 경우
    if (this.$route.name === 'detail-reservation') {
      const dateTime = this.type.reservation_date;
      const date = dateTime.substr(0, 10);
      const time = dateTime.substr(11, 5);

      this.selectedDate = new Date(date);
      this.params.date = new Date(date);
      this.params.person = this.type.party_size;
      this.person = this.type.party_size;
      this.params.id = this.type.product ? this.type.product.id : this.type.place_id;

      // 처음 진입시 schedule data가 없어서 오류
      // const index = this.schedule.schedules.findIndex((item) => {
      //   return item.time === time;
      // });
      // this.timeCheck = index;
      this.reserveTime = time;
      this.openState = false;
    }
    this.resetData();
    this.activeSwiperTime(this.timeCheck);
    this.getCalendarInfo();
    const calendarPrev: any = document.querySelector('.vc-arrow.is-left');
    const calendarNext: any = document.querySelector('.vc-arrow.is-right');
    const calendarScroll: any = document.querySelector('.vc-pane-container');
    calendarPrev.addEventListener('click', () => {
      const el: any = document.getElementsByClassName('vc-day');
      for (const item of el) {
        item.classList.add('notranslate');
      }
      setTimeout(() => {
        this.getCalendarInfo();
      }, 300);
    });
    calendarNext.addEventListener('click', () => {
      const el: any = document.getElementsByClassName('vc-day');
      for (const item of el) {
        item.classList.add('notranslate');
      }
      setTimeout(() => {
        this.getCalendarInfo();
      }, 300);
    });
    calendarScroll.addEventListener('touchmove', () => {
      setTimeout(() => {
        const el: any = document.getElementsByClassName('vc-day');
        for (const item of el) {
          item.classList.add('notranslate');
        }
      }, 100);
      setTimeout(() => {
        this.getCalendarInfo();
      }, 300);
    });
  }
  public getCalendarInfo() {
    const el: any = document.getElementsByClassName('vc-day');
    for (const item of el) {
      item.classList.add('notranslate');
    }
    if (this.selectLang !== 'ko') {
      setTimeout(() => {
        const parent: any = document.getElementsByClassName('vc-weeks')[0];
        parent.childNodes[0].innerHTML = 'sun';
        parent.childNodes[1].innerHTML = 'mon';
        parent.childNodes[2].innerHTML = 'tue';
        parent.childNodes[3].innerHTML = 'wed';
        parent.childNodes[4].innerHTML = 'thu';
        parent.childNodes[5].innerHTML = 'fri';
        parent.childNodes[6].innerHTML = 'sat';
      });
    }
  }
  public modalClosed() {
    this.openState = false;
    document.querySelector('html' as any).classList.remove('open-modal');
    if (this.$route.name === 'place' && this.type.reservation_type === 'normal') {
      this.firstParams.date = this.params.date;
      this.firstParams.person = this.params.person;
      this.firstParams.time = this.reserveTime;
    }
    this.firstPersonKind = [];
    for (let i = 0; i < this.personKind.length; i++) {
      this.firstPersonKind.push(this.personKind[i].person);
    }
    this.firstParams.except_person = this.params.except_person;
  }
  public destroyed() {
    document.querySelector('html' as any).classList.remove('open-modal');
  }

  public disableDate(date) {
    const isFind = find(this.schedule.off_days, (row) => row === date);
    if (isFind) {
      this.params.date = null;
      this.selectedDate = null;
    }
  }

  @Watch('$route.params')
  public routeChange() {
    this.reserveTime = null;
    this.firstParams.time = null;
  }

  @Watch('personKind', {
    deep: true
  })
  public personKindUpdate() {
    let total = 0;
    let inquiryTotal = 0;
    for (let i = 0; i < this.personKind.length; i++) {
      if (!this.personKind[i].name.includes('유아')) {
        inquiryTotal = inquiryTotal + this.personKind[i].person;
      }
      total = total + this.personKind[i].person;
    }
    this.person = total;
    this.inquiryPerson = inquiryTotal;
    this.optionChecked = [];
    this.fetchOptionData();
    this.$nextTick(() => {
      this.activeSwiperTime();
    });
  }
  @Watch('reservationTimeData')
  public reservationTimeDataUpdate() {
    if (this.reservationTimeData) {
      this.personMin = this.reservationTimeData.capacity[0].size;
      this.personMax = this.reservationTimeData.capacity[this.reservationTimeData.capacity.length - 1].size;
      if (
        this.person >= this.personMin &&
        this.person <= this.personMax
      ) {
        return;
      } else {
        // this.person = 0;
        // this.inquiryPerson = 0;
        // this.params.person = 0;
        // if (this.reservationTimeData.party_size_detail.length) {
        //   for (let i = 0; i < this.personKind.length; i++) {
        //     this.personKind[i].person = 0;
        //   }
        // }
      }
    }
  }
  @Watch('openState')
  public openStateUpdate() {
    this.$emit('reserve-modal', this.openState);
  }

  // 스케줄 api
  get schedule() {
    if (this.$route.name === 'product') {
      return reservationModule.productSchedule;
    } else if (this.$route.name === 'place') {
      return reservationModule.placeSchedule;
    } else {
      if (this.type.product === null) {
        return reservationModule.placeSchedule;
      }
      return reservationModule.productSchedule;
    }
  }

  get calendarData() {
    return reservationModule.productCalendar;
  }
  get reservationTimeData() {
    return reservationModule.productReservationTime;
  }
  get timeBlockData() {
    if (this.reservationTimeData) {
      if (this.reservationTimeData.party_size_detail && this.reservationTimeData.party_size_detail.length) {
        const re = this.reservationTimeData.capacity.filter((row: any) => {
          // return row.size === this.personTotal; zz
          return row.size === this.person;
        });
        return re[0];
      } else {
        const re = this.reservationTimeData.capacity.filter((row: any) => {
          return row.size === this.person;
        });
        return re[0];
      }
    }
  }
  get productOptions() {
    return reservationModule.productOptions;
  }
  get timeBlockDataDisabled() {
    let re = false;
    if (this.timeBlockData && this.timeBlockData.times) {
      this.timeBlockData.times.filter((row: any) => {
        if (row.is_active) {
          re = true;
        }
      });
    }
    return re;
  }
}
